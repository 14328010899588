const { instance } = require("./instances/current.json");
const isEEG = instance.includes("EEG");
const isIPAM = instance.includes("IPAM");
const isCEG = instance.includes("CEG");
const isIADEES = instance.includes("iadeES");
const isIADE = instance.includes("IADE");

const googleVerification = (
	<meta
		name="google-site-verification"
		content="lbY8tLaIe6OHuriBjWwDLT65ENA-5C9Hx8mEI_MG3OA"
	/>
);

const noCookie = (
	<style
		type="text/css"
		dangerouslySetInnerHTML={{
			__html: `#ot-sdk-btn-floating {display: none; visibility: hidden;}`,
		}}
	/>
);

const utmLaunch = (
	<script
		type="text/javascript"
		className="optanon-category-C0002"
		dangerouslySetInnerHTML={{
			__html: `
      const setCookie = (name, value, days) => {
            let expires = "";
            if (days) {
                const date = new Date()
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
                expires = "; expires" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/"
        }
        const getCookie = (name) => {
            if (typeof document !== undefined) {
                let value = '; '+document.cookie
                let parts = value.split('; '+name)
                if (parts.length === 2) return parts.pop().split(';').shift()
                else if (parts.length > 2) return parts.slice(1).map(cookie => cookie.split(';').shift())
            }
        }
        const saveUTM = (url = document.URL, utmKeysPrefix = 'utm_') => {
            if (!url) return {}
            const splitUrl = url.split('?')
            if (splitUrl.length < 2) return {}
            splitUrl.shift(1)
            const response = {}
            splitUrl
                .join('?')
                .split('&')
                .filter((item) => !!item && item.startsWith(utmKeysPrefix))
                .map((item) => item.split('='))
                .map((item) => (response[item[0]] = item[1]))
            const activeUtms = (getCookie('activeUtm') || '')
                .split(',')
                .filter((item) => !!item)
            for (const utm in response) {
                setCookie(utm, response[utm], 1)
                if (!activeUtms.includes(utm)) activeUtms.push(utm)
            }
            setCookie('activeUtm', activeUtms.join(','), 1)
        }
        const saveGCLID = (url) => {
            if (!url) return {}
            const startIdx = url.indexOf('gclid=')
            if (startIdx === -1) return {}
            const gclid = url.substr(startIdx + 6)
            let uEGCLID = ''
            if (gclid.includes('#')) {
                uEGCLID = gclid.substr(0, gclid.indexOf('#'))
            } else if (gclid.includes('&')) {
                uEGCLID = gclid.substr(0, gclid.indexOf('&'))
            } else {
                uEGCLID = gclid
            }
            setCookie('gclid', uEGCLID, 1)
        }
        
        const getSessionStorage = (key) => {
        	if (typeof window !== "undefined") {
         		return window.sessionStorage.getItem(key);
         	}
         	
         	return null;
        }
        
        const setSessionStorage = (key, value) => {
					if (typeof window !== "undefined") {
						return window.sessionStorage.setItem(key, value);
					}
					return null;
				};
        
        const saveReferralCookie = () => {
					const referral_url = getSessionStorage("referral_url");
					if(!referral_url) {
						const referralUrl = document.referrer || "direct";
						setSessionStorage("referral_url", referralUrl);
					}
				}
				saveUTM(window?.location?.href);
				saveGCLID(window?.location?.href);
				saveReferralCookie()
      `,
		}}
	/>
);

/* eslint-disable react/no-unknown-property */
const oneTrustCookiesCEG = (
	<script
		key="cookieLaw"
		src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
		data-document-language="true"
		type="text/javascript"
		charset="UTF-8"
		data-domain-script="feb85720-c60f-42b6-a5a5-3f74ef62855a"
		defer="defer"
	/>
);

const oneTrustCookiesCEGPre = (
	<script
		key="cookieLaw"
		src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
		data-document-language="true"
		type="text/javascript"
		charSet="UTF-8"
		data-domain-script="feb85720-c60f-42b6-a5a5-3f74ef62855a-test"
		defer="defer"
	/>
);

const oneTrustCookiesEEG = (
	<script
		key="cookieLaw"
		src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
		type="text/javascript"
		charset="UTF-8"
		data-domain-script="fbae7e91-2bd4-4a8a-9ed7-542f7c7cff13"
		defer="defer"
	/>
);

const oneTrustCookiesEEGPre = (
	<script
		key="cookieLaw"
		src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
		type="text/javascript"
		charset="UTF-8"
		data-domain-script="fbae7e91-2bd4-4a8a-9ed7-542f7c7cff13-test"
		defer="defer"
	/>
);

const oneTrustCookiesIPAM = (
	<script
		key="cookieLaw"
		src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
		type="text/javascript"
		charset="UTF-8"
		data-domain-script="c9d96b3b-c03c-411a-b260-119292d6c91b"
		defer="defer"
	/>
);

const oneTrustCookiesIPAMPre = (
	<script
		key="cookieLaw"
		src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
		type="text/javascript"
		charset="UTF-8"
		data-domain-script="c9d96b3b-c03c-411a-b260-119292d6c91b-test"
		defer="defer"
	/>
);

const oneTrustCookiesIADEES = (
	<script
		key="cookieLaw"
		src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
		type="text/javascript"
		charset="UTF-8"
		data-domain-script="81a40cd8-4ddd-46d1-b23d-d0fbcc895556"
		defer="defer"
	/>
);

const oneTrustCookiesIADEESPre = (
	<script
		key="cookieLaw"
		src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
		type="text/javascript"
		charset="UTF-8"
		data-domain-script="81a40cd8-4ddd-46d1-b23d-d0fbcc895556-test"
		defer="defer"
	/>
);

const oneTrustCookiesWrapper = (
	<script
		key="cookieLaw"
		type="text/javascript"
		dangerouslySetInnerHTML={{
			__html: `function OptanonWrapper() {}`,
		}}
	/>
);

const cookieDelayShowBannerMobile = (
	<script
		dangerouslySetInnerHTML={{
			__html: `function showBannerCP() {
            var banner = document.getElementById("onetrust-consent-sdk");
            banner.style.display = "block";
            window.removeEventListener("touchstart", showBannerCP);
            window.removeEventListener('scroll', showBannerCP);
            window.removeEventListener('click', showBannerCP);
            window.removeEventListener('mousemove', showBannerCP);
        }
        if (window.innerWidth <= 800) {
            document.addEventListener("DOMNodeInserted", function (e) {
                if (e.target.id == "onetrust-consent-sdk") {
                    var banner = document.getElementById("onetrust-consent-sdk");
                    banner.style.display = "none";
                    window.addEventListener("touchstart", showBannerCP);
                    window.addEventListener("scroll", showBannerCP);
                    window.addEventListener("click", showBannerCP);
                    window.addEventListener("mousemove", showBannerCP);
                    (function () {
                        setTimeout(function () {
                            showBannerCP();
                        }, 5000);
                    })();
                }
            }, false);
        }`,
		}}
	/>
);

const webVitals = (
	<script
		key="cookieLaw"
		type="text/javascript"
		dangerouslySetInnerHTML={{
			__html: ` (function () {
    var script = document.createElement('script');
    script.src =
      'https://unpkg.com/web-vitals@4/dist/web-vitals.attribution.iife.js';
    script.onload = function () {
      // When loading \`web-vitals\` using a classic script, all the public
      // methods can be found on the \`webVitals\` global namespace.
      webVitals.onCLS(console.log);
      webVitals.onINP(console.log);
      webVitals.onLCP(console.log);
    };
    document.head.appendChild(script);
  })();`,
		}}
	/>
);

const preconnectCookielaw = (
	<link rel="dns-prefetch" href="https://cdn.cookielaw.org" />
);

const prefetchCookielaw = (
	<link
		rel="preconnect"
		href="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
	/>
);

const prefetchCloudinary = (
	<link rel="dns-prefetch" href="https://res.cloudinary.com" />
);

// ASSETS IPAM
const preconnectAssetsIPAMPre = (
	<link rel="preconnect" href="https://assets.pre.griddo.ipam.pt/" />
);

const prefetchAssetsIPAMPre = (
	<link rel="dns-prefetch" href="https://assets.pre.griddo.ipam.pt/" />
);
const preconnectAssetsIPAM = (
	<link rel="preconnect" href="https://assets.griddo.ipam.pt/" />
);

const prefetchAssetsIPAM = (
	<link rel="dns-prefetch" href="https://assets.griddo.ipam.pt/" />
);

// ASSETS IADE
const preconnectAssetsIADEPre = (
	<link rel="preconnect" href="https://assets.pre.griddo.iade.europeia.pt/" />
);

const prefetchAssetsIADEPre = (
	<link rel="dns-prefetch" href="https://assets.pre.griddo.iade.europeia.pt/" />
);
const preconnectAssetsIADE = (
	<link rel="preconnect" href="https://assets.griddo.iade.europeia.pt/" />
);

const prefetchAssetsIADE = (
	<link rel="dns-prefetch" href="https://assets.griddo.iade.europeia.pt/" />
);

// ASSETS EEG
const preconnectAssetsEEGPre = (
	<link
		rel="preconnect"
		href="https://assets.pre.griddo.europaeducationgroup.com/"
	/>
);

const prefetchAssetsEEGPre = (
	<link
		rel="dns-prefetch"
		href="https://assets.pre.griddo.europaeducationgroup.com/"
	/>
);
const preconnectAssetsEEG = (
	<link
		rel="preconnect"
		href="https://assets.griddo.europaeducationgroup.com/"
	/>
);

const prefetchAssetsEEG = (
	<link
		rel="dns-prefetch"
		href="https://assets.griddo.europaeducationgroup.com/"
	/>
);

// ASSETS CEG
const preconnectAssetsCEGPre = (
	<link
		rel="preconnect"
		href="https://assets.pre.griddo.centrogarrigues.com/"
	/>
);

const prefetchAssetsCEGPre = (
	<link
		rel="dns-prefetch"
		href="https://assets.pre.griddo.centrogarrigues.com/"
	/>
);
const preconnectAssetsCEG = (
	<link rel="preconnect" href="https://assets.griddo.centrogarrigues.com/" />
);

const prefetchAssetsCEG = (
	<link rel="dns-prefetch" href="https://assets.griddo.centrogarrigues.com/" />
);

// ASSETS IADE ES
const preconnectAssetsIadeESPre = (
	<link rel="preconnect" href="https://assets.pre.griddo.iade.es/" />
);

const prefetchAssetsIadeESPre = (
	<link rel="dns-prefetch" href="https://assets.pre.griddo.iade.es/" />
);
const preconnectAssetsIadeES = (
	<link rel="preconnect" href="https://assets.griddo.iade.es/" />
);

const prefetchAssetsIadeES = (
	<link rel="dns-prefetch" href="https://assets.griddo.iade.es/" />
);

// En AX siempre cargamos todas las calses de Grisso para tener acceso a todas
// las clases de Grisso.
const grissoDevMode = (
	<link rel="stylesheet" href="/grisso.css" as="style" id="grisso" />
);

const instancesToInclude = [
	"CEG",
	"EEG",
	"IPAM",
	"IADE",
	"iadeES",
	"CEG-pre",
	"CEG-PRE",
	"EEG-pre",
	"EEG-PRE",
	"IPAM-pre",
	"IPAM-PRE",
	"IADE-pre",
	"IADE-PRE",
	"iadeES-PRE",
	"iadeES-pre",
];

function getPreconnectAndPrefetchAssets() {
	if (instancesToInclude.includes(instance)) {
		switch (instance) {
			case "IPAM-pre":
			case "IPAM-PRE":
				return [preconnectAssetsIPAMPre, prefetchAssetsIPAMPre];
			case "IPAM":
				return [preconnectAssetsIPAM, prefetchAssetsIPAM];
			case "IADE-pre":
			case "IADE-PRE":
				return [preconnectAssetsIADEPre, prefetchAssetsIADEPre];
			case "IADE":
				return [preconnectAssetsIADE, prefetchAssetsIADE];
			case "EEG-pre":
			case "EEG-PRE":
				return [preconnectAssetsEEGPre, prefetchAssetsEEGPre];
			case "EEG":
				return [preconnectAssetsEEG, prefetchAssetsEEG];
			case "CEG-pre":
			case "CEG-PRE":
				return [preconnectAssetsCEGPre, prefetchAssetsCEGPre];
			case "CEG":
				return [preconnectAssetsCEG, prefetchAssetsCEG];
			case "iadeES-pre":
			case "iadeES-PRE":
				return [preconnectAssetsIadeESPre, prefetchAssetsIadeESPre];
			case "iadeES":
				return [preconnectAssetsIadeES, prefetchAssetsIadeES];
			default:
				return [];
		}
	}
}

const getOneTrustCookies = () => {
	if (isIADE) {
		return;
	}

	if (isIPAM) {
		if (instance === "IPAM") {
			return oneTrustCookiesIPAM;
		}
		return oneTrustCookiesIPAMPre;
	}

	if (isEEG) {
		if (instance === "EEG") {
			return oneTrustCookiesEEG;
		}
		return oneTrustCookiesEEGPre;
	}

	if (isCEG) {
		if (instance === "CEG") {
			return oneTrustCookiesCEG;
		}
		return oneTrustCookiesCEGPre;
	}

	if (isIADEES) {
		if (instance === "iadeES") {
			return oneTrustCookiesIADEES;
		}
		return oneTrustCookiesIADEESPre;
	}
};

function onRenderBody({ setHeadComponents, setBodyAttributes, pathname }) {
	let headComponents = [];

	if (pathname === "ax-editor") {
		headComponents.push(grissoDevMode);
	}

	if (instancesToInclude.includes(instance)) {
		if (pathname !== "ax-editor") {
			headComponents = [utmLaunch, noCookie, cookieDelayShowBannerMobile];

			if (!isIADE) {
				headComponents.unshift(oneTrustCookiesWrapper);
			}

			if (isEEG) {
				headComponents.push(googleVerification);
			}

			headComponents.push(getOneTrustCookies());
		}
	}

	if (
		instance.includes("PRE") ||
		instance.includes("pre") ||
		instance.includes("DEV") ||
		instance.includes("dev")
	) {
		headComponents.push(webVitals);
	}

	setHeadComponents(headComponents);
	setBodyAttributes({ id: "garrigues-site" });
}

function onPreRenderHTML({
	getHeadComponents,
	replaceHeadComponents,
	getPostBodyComponents,
	replacePostBodyComponents,
}) {
	if (instancesToInclude.includes(instance)) {
		const postBodyComponents = getPostBodyComponents();
		const headComponents = getHeadComponents();

		// Move cookieLaw script to the top of the head section so it loads first.
		headComponents.sort((x, y) => {
			if (x.key === "cookieLaw") {
				return -1;
			}
			if (y.key === "cookieLaw") {
				return 1;
			}
			return 0;
		});

		const allComponents = [
			preconnectCookielaw,
			prefetchCookielaw,
			prefetchCloudinary,
			...getPreconnectAndPrefetchAssets(),
			...postBodyComponents,
			headComponents,
		];

		replaceHeadComponents(allComponents);
		replacePostBodyComponents([]);
	}
}

export default {
	onRenderBody,
	onPreRenderHTML,
};
