import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/";

const isIPAM = instance.includes("IPAM");
const isIADE = instance.includes("IADE");
const isIADEES = instance.includes("iadeES");
const isCEG = instance.includes("CEG");

let defaultTheme = "defaultAlt";

if (isIADEES || isIADE) {
	defaultTheme = "accent";
}

export default createSchema({
	schemaType: "module",
	displayName: "Overview",
	component: "Overview",
	dataPacks: null,
	category: "interactive",
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "HeadingField",
					humanReadable: true,
					key: "title",
					advanced: true,
					mandatory: true,
					default: { tag: "h2", content: "Default placeholder" },
					options: [
						{ value: "h1", label: "H1" },
						{ value: "h2", label: "H2" },
						{ value: "h3", label: "H3" },
						{ value: "h4", label: "H4" },
						{ value: "span", label: "span" },
					],
				},
				{
					title: "Description",
					type: "RichText",
					humanReadable: true,
					key: "description",
					mandatory: true,
					html: true,
				},
				{
					title: "Conditional form",
					type: "ConditionalField",
					key: "conditionalForm",
					mandatory: true,
					options: [
						{
							value: true,
							title: "QuickFacts + Information Request Form",
							name: "QuickFacts",
						},
						{
							value: false,
							title: "Highlighted Download + Contact from ",
							name: "HighlightedDownload",
						},
						{
							value: "Complaint",
							title: "Complaint form",
							name: "Complaint",
						},
					],
					fields: [
						{
							title: "Quick Facts",
							type: "ComponentContainer",
							whiteList: ["QuickFacts"],
							key: "quickFacts",
							mandatory: true,
							condition: true,
						},
						...(isIPAM || isIADE || isIADEES || isCEG
							? [
									{
										title: "Button",
										type: "ComponentContainer",
										whiteList: ["Button"],
										key: "downloadButton",
										hideable: true,
										condition: true,
									},
									{
										title: "File",
										type: "FileField",
										key: "file",
										hideable: true,
										condition: true,
									},
							  ]
							: []),
						{
							title: "Email",
							type: "TextField",
							key: "email",
							mandatory: true,
							hideable: false,
							condition: false,
						},
						{
							title: "Title",
							type: "TextField",
							humanReadable: true,
							key: "complaintTitle",
							mandatory: true,
							hideable: false,
							condition: "Complaint",
						},
						{
							title: "Text",
							type: "RichText",
							humanReadable: true,
							key: "complaintText",
							hideable: true,
							condition: "Complaint",
						},
						{
							title: "Email",
							type: "TextField",
							key: "email",
							mandatory: true,
							hideable: false,
							condition: "Complaint",
						},
						{
							title: "Highlighted Download",
							type: "ComponentContainer",
							whiteList: ["HighlightedDownload"],
							key: "highlightedDownload",
							hideable: true,
							condition: false,
						},
					],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{ ...createThemeStyles("garrigues-theme", ["defaultAlt"]) },
						{ ...createThemeStyles("eeg-theme", ["accent"]) },
						{ ...createThemeStyles("ipam-theme", ["defaultAlt", "accentAlt"]) },
						{ ...createThemeStyles("iade-theme", ["accent"]) },
						{ ...createThemeStyles("iade-es-theme", ["accent"]) },
						{ ...createThemeStyles("uddi-theme", ["defaultAlt"]) },
					],
					columns: 8,
				},
			],
		},
	],
	default: {
		component: "Overview",
		theme: defaultTheme,
		title: { content: "Title", tag: "h4" },
		description:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
		conditionalForm: true,
		email: "example@email.com",
		quickFacts: {
			component: "QuickFacts",
			elements: [
				{
					component: "QuickFactsElement",
					title: "Lorem Ipsum",
					description: "Lorem ipsum dolor ",
				},
			],
		},
		...((isIPAM || isIADE || isIADEES || isCEG) && {
			downloadButton: {
				component: "Button",
				buttonText:
					isCEG || isIADEES ? "Descarga el catálogo" : "Download Brochura",
				appearance: "secondary",
				size: "big",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
			file: "",
		}),
		highlightedDownload: {
			component: "HighlightedDownload",
			title: null,
			subtitle: "Lorem Ipsum",
			alt: true,
			button: {
				component: "Button",
				buttonText: "Descargar",
				appearance: "secondary",
				size: "medium",
				url: {
					href: "",
					linkTo: null,
					newTab: false,
					noFollow: false,
				},
			},
		},
		complaintTitle: "Ayúdanos a mejorar",
		complaintText:
			"Si deseas realizar cualquier sugerencia, queja o reclamación respecto a tu experiencia o procesos relacionados con el Centro de Estudios Garrigues puedes realizarlo a través del siguiente formulario",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Overview_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/Overview_x2.png`,
	},
});
